<template>
  <div class="main" data-title="公告通知" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">公告通知</h2>
        <div class="head-search">
          <el-form class="form">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="公告标题">
                  <el-input
                    placeholder="请输入"
                    suffix-icon="el-icon-edit"
                    v-model="key.title"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否生效">
                  <el-select placeholder="请选择" v-model="key.status">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="生效" :value="1"></el-option>
                    <el-option label="失效" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="发布日期">
                  <el-date-picker
                    @change="changeDate"
                    align="right"
                    end-placeholder="结束日期"
                    range-separator="-"
                    start-placeholder="开始日期"
                    type="daterange"
                    unlink-panels
                    v-model="begin_end_date"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item class="label-block" label=" ">
                  <el-button
                    @click="handleCurrentChange(1)"
                    icon="el-icon-search"
                    type="primary"
                    >搜索</el-button
                  >
                  <el-button @click="resetSeachKey">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">公告列表</div>
        <div class="jue-card-btns">
          <el-button
            @click="$router.push({ name: 'news-edit' })"
            class="btn"
            icon="el-icon-edit"
            type="primary"
            v-if="getAuth('add')"
            >新建</el-button
          >
        </div>
      </div>

      <div class="table-wrapper">
        <el-table
          :data="newsData"
          :style="'width: ' + $store.state.tableWidth"
          class="table"
          max-height="250"
          style="width: 100%"
        >
          <el-table-column label="标题" prop="title"></el-table-column>
          <el-table-column label="发布日期" prop="created_at"></el-table-column>
          <el-table-column
            label="截止有效日期"
            prop="expired_date"
          ></el-table-column>
          <!-- <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <el-switch
                :active-value="1"
                :inactive-value="2"
                @change="changeStatus(scope.row)"
                active-color="#13ce66"
                inactive-color="#DCDFE6"
                v-has="'news-set_status'"
                v-model="scope.row.status"
              ></el-switch>
            </template>
          </el-table-column>-->
          <el-table-column fixed="right" label="操作" width="180px">
            <template slot-scope="scope">
              <el-button
                @click="
                  $router.push({
                    name: 'news-view',
                    query: { news_id: scope.row.news_id },
                  })
                "
                size="small"
                type="text"
                >查看</el-button
              >
              <span class="jue-divider"></span>

              <el-button
                @click="
                  $router.push({
                    name: 'news-edit',
                    query: { news_id: scope.row.news_id },
                  })
                "
                size="small"
                type="text"
                v-if="getAuth('edit')"
                >修改</el-button
              >
              <span class="jue-divider" v-has="'news-remove'"></span>

              <el-button
                @click="deleteItem(scope.$index, scope.row)"
                class="btn-delete"
                size="small"
                type="text"
                v-if="getAuth('delete')"
                >删除</el-button
              >

              <!-- <el-button class="btn-delete" size="small" type="text">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="key.count"
          :total="total"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      base: {
        popover: false,
      },
      begin_end_date: null,
      total: 0,
      key: {
        page: 1,
        count: 15,
        begin_date: '',
        end_date: '',
        title: '',
        status: 0,
      },
      newsData: [],
    }
  },
  mounted() {
    this.getNewsData()
  },
  methods: {
    getAuth(str) {
      let pa = this.$route.path.split('/')
      if (this.$store.state.auth[pa[1]][pa[2]][str] == undefined) {
        return false
      } else {
        return true
      }
    },
    changeDate() {
      if (this.begin_end_date == null) {
        this.key.begin_date = ''
        this.key.end_date = ''
      } else {
        this.key.begin_date = this.begin_end_date[0]
        this.key.end_date = this.begin_end_date[1]
      }
    },
    resetSeachKey() {
      this.begin_end_date = null
      this.key.title = ''
      this.key.status = 0
    },
    //点击分页
    handleCurrentChange(val) {
      this.key.page = val
      this.getNewsData()
    },
    changeStatus(data) {
      this.$axios
        .post('/news/set_status', {
          news_id: data.news_id,
          status: data.status,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    getNewsData() {
      this.$axios.post('/news/lists', this.key).then((res) => {
        if (res.data.code == 200) {
          this.newsData = res.data.data.list
          this.total = res.data.data.total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    deleteItem(index, item) {
      this.$confirm('此操作将永久删除 ' + item.title + ' , 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post('/news/remove', {
              news_id: item.news_id,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.getNewsData()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.form {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  /deep/ .el-select,
  /deep/ .el-date-editor {
    width: 100%;
  }
}

.footer {
  text-align: right;
  padding: 20px 0;
}
</style>